body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  text-align: center;
  align-items: center;
  justify-content: center;

  background-position: top;
  background-repeat: no-repeat;

  /* 화면 자체의 가로 세로 */
  max-width: 450px;
  min-width: 340px;
  width: 100%;
  height: 100vh;

  margin-left: auto;
  margin-right: auto;

  /* 백그라운드이미지의 가로세로 */
  background-size: 100% 1000px;

  font-family: "misaeng";

}

ul {
  list-style: none;
  padding-left: 0px;
}

.stretch_container {
  text-align: center;
  align-items: center;
  justify-content: center;

  background-position: top;
  background-repeat: no-repeat;

  /* 화면 자체의 가로 세로 */
  max-width: 450px;
  min-width: 340px;
  width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: auto;

  /* 백그라운드이미지의 가로세로 */
  background-size: 100% 100%;

  font-family: "misaeng";

}

.img_1 {
  margin-top: 45vh;
  width: 300px;
  height: auto;
  /* object-fit: cover ; */
}

.img_2 {
  width: 300px;
  height: auto;
  /* object-fit: cover ; */
}

.img_3 {
  width: 150px;
  height: auto;
  /* object-fit: cover ; */
}

.img_4 {
  width: 143px;
  height: auto;
  /* object-fit: cover ; */
}

.img_5 {
  width: 120px;
  height: auto;
  /* object-fit: cover ; */
}

.img_6 {
  width: 180px;
  height: auto;
  /* object-fit: cover ; */
}

.light{
  font-weight:lighter;
}

.right{
  text-align: right;
  margin-right:10px;
  margin-top:0px;
}

.mtop{
  padding-top:19vh;
  margin-top:0vh;
  
}

.totalstyle{
  border-bottom : 2px dashed black;
}

@font-face {
  /* 사용할 폰트의 이름 */
  font-family: "misaeng";
  /* normal : 보통 굵기입니다. 숫자 400과 같습니다.
    bold : 굵은 굵기입니다. 숫자 700과 같습니다.
    bolder : 상속된 값보다 굵은 굵기입니다.
    lighter : 상속된 값보다 얇은 굵기입니다.
    number : 100, 200, 300, 400, 500, 600, 700, 800, 900
    initial : 기본값으로 설정합니다.
    inherit : 부모 요소의 속성값을 상속받습니다. */
  font-display: fallback;

  /* 
  src: 사용할 폰트의 위치 및 형식 
     Safari, Android, iOS => ttf
     Modern Browsers => woff
     IE6-IE8 => eot
  */
  src: url(/static/media/misaeng.27d7c081.ttf) format("truetype"),
    url(/static/media/misaeng.e042e3ea.woff) format("woff"),
    url(/static/media/misaeng.f6712f8f.eot) format("embedded-opentype");

  /* 사용할 폰트의 기본 스타일 */

  /* font-style: lighter; */
}


.accordion {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.accordion .accordion-item:first-child .title {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.accordion .accordion-item:last-child .title {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.accordion .accordion-item .title {
  background-color: #f6f6f6;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  font-family: "misaeng";
  font-size: 18px;

}
.accordion .accordion-item .title:after {
  content: '\2795';
  font-size: 18px;
  color: #777;
  float: right;
  margin-left: 5px;
}
.accordion .accordion-item .panel {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}
.accordion .accordion-item.active .title {
  background-color: #eee;
}
.accordion .accordion-item.active .title:after {
  content: '\2796';
}
.accordion .accordion-item.active .panel {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}
/*# sourceMappingURL=index.css.map*/


.UploadfileImage {
    text-align: center !important;
    width: 100% !important;
    height: auto !important;
    margin: auto !important;
}

.btn {
    padding: 10px 40px !important;
    background: #000 !important;
    border: 0 !important;
    outline: none !important;
    cursor: pointer !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    border-radius: 30px !important;
    color: #fff;
}

.gift-parent {
    text-align: center;
    display: flex;
}

.gift-child{
    flex: 1 1;
}

.dashLine{
    border-bottom : 1px dashed black;
}

.gift-child-img{
    width: auto;
    height: 100px;   
}

.uploadfileContainer {

    height: auto;
    margin: 0 auto;
    max-width: 500px;
    text-align: center;
    justify-content: center;
}


.containerMainPage {
    text-align: center;
    background-position: top;
    background-repeat: no-repeat;

    /* 화면 자체의 가로 세로 */
    min-width: 500px;
    height: 100vh;

    /* 백그라운드이미지의 가로세로 */
    background-size: 500px 1000px;
    /* background-size:cover;  */

    align-items: center;
    justify-content: center;

    font-family: "misaeng";
}

.backgroundMainPage {
    background-size: cover;
    background-size: cover;
}

/* uploadfile 내 파일 업로드 버튼 */
.uploadFileBtn{
    border: none;
    outline : 0;
    background: none;
    box-shadow: none;
}

.uploadFileImg{
    margin: 0 8px 0 8px;
    max-width: 150px;
}


