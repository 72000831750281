.UploadfileImage {
    text-align: center !important;
    width: 100% !important;
    height: auto !important;
    margin: auto !important;
}

.btn {
    padding: 10px 40px !important;
    background: #000 !important;
    border: 0 !important;
    outline: none !important;
    cursor: pointer !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    border-radius: 30px !important;
    color: #fff;
}

.gift-parent {
    text-align: center;
    display: flex;
}

.gift-child{
    flex: 1;
}

.dashLine{
    border-bottom : 1px dashed black;
}

.gift-child-img{
    width: auto;
    height: 100px;   
}

.uploadfileContainer {

    height: auto;
    margin: 0 auto;
    max-width: 500px;
    text-align: center;
    justify-content: center;
}


.containerMainPage {
    text-align: center;
    background-position: top;
    background-repeat: no-repeat;

    /* 화면 자체의 가로 세로 */
    min-width: 500px;
    height: 100vh;

    /* 백그라운드이미지의 가로세로 */
    background-size: 500px 1000px;
    /* background-size:cover;  */

    align-items: center;
    justify-content: center;

    font-family: "misaeng";
}

.backgroundMainPage {
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

/* uploadfile 내 파일 업로드 버튼 */
.uploadFileBtn{
    border: none;
    outline : 0;
    background: none;
    box-shadow: none;
}

.uploadFileImg{
    margin: 0 8px 0 8px;
    max-width: 150px;
}

